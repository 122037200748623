import p1 from './sedinte BOWLING, IDM CLUB, Bucuresti, Mos 2024-1.jpeg';
import p2 from './sedinte BOWLING, IDM CLUB, Bucuresti, Mos 2024-2.jpeg';
import p3 from './sedinte BOWLING, IDM CLUB, Bucuresti, Mos 2024-3.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 1600,
            height: 1200
          },
  {
            src: p3,
            width: 1500,
            height: 2000
          }
];