import p1 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-1.jpeg';
import p2 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-10.jpeg';
import p3 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-11-13.jpeg';
import p4 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-11.jpeg';
import p5 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-12.jpeg';
import p6 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-2.jpeg';
import p7 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-3.jpeg';
import p8 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-4.jpeg';
import p9 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-5.jpeg';
import p10 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-6.jpeg';
import p11 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-7.jpeg';
import p12 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-8.jpeg';
import p13 from './tabara de integrare si socializare, BUSTENI, 11-13.10.24-9.jpeg';
import p14 from './WhatsApp Image 2025-01-04 at 21.23.53.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 1200,
            height: 1600
          },
  {
            src: p3,
            width: 1500,
            height: 2000
          },
  {
            src: p4,
            width: 1200,
            height: 1600
          },
  {
            src: p5,
            width: 1500,
            height: 2000
          },
  {
            src: p6,
            width: 1500,
            height: 2000
          },
  {
            src: p7,
            width: 1500,
            height: 2000
          },
  {
            src: p8,
            width: 2000,
            height: 1500
          },
  {
            src: p9,
            width: 1500,
            height: 2000
          },
  {
            src: p10,
            width: 1500,
            height: 2000
          },
  {
            src: p11,
            width: 1500,
            height: 2000
          },
  {
            src: p12,
            width: 899,
            height: 1599
          },
  {
            src: p13,
            width: 1600,
            height: 1200
          },
  {
            src: p14,
            width: 1080,
            height: 1159
          }
];