import p1 from './eveniment MOSUL LA SUPEREROI, COTROCENI, 14.12.24-1.jpeg';
import p2 from './eveniment MOSUL LA SUPEREROI, COTROCENI, 14.12.24-2.jpeg';
import p3 from './eveniment MOSUL LA SUPEREROI, COTROCENI, 14.12.24-3.jpeg';
import p4 from './eveniment MOSUL LA SUPEREROI, COTROCENI, 14.12.24-4.jpeg';
import p5 from './eveniment MOSUL LA SUPEREROI, COTROCENI, 14.12.24-5.jpeg';
import p6 from './eveniment MOSUL LA SUPEREROI, COTROCENI, 14.12.24-6.jpeg';

export const content = [
  {
            src: p1,
            width: 1600,
            height: 1200
          },
  {
            src: p2,
            width: 1500,
            height: 2000
          },
  {
            src: p3,
            width: 1500,
            height: 2000
          },
  {
            src: p4,
            width: 1500,
            height: 2000
          },
  {
            src: p5,
            width: 2000,
            height: 1500
          },
  {
            src: p6,
            width: 2048,
            height: 1983
          }
];