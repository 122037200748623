import p1 from './spectacol INCHIDE OCHII SI AI SA VEZI MAI BINE, Teatru Comedie 1.jpeg';
import p2 from './spectacol INCHIDE OCHII SI AI SA VEZI MAI BINE, Teatru Comedie 2.jpeg';
import p3 from './spectacol INCHIDE OCHII SI AI SA VEZI MAI BINE, Teatru Comedie 3.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 1500,
            height: 2000
          },
  {
            src: p3,
            width: 1500,
            height: 2000
          }
];