import p1 from './ZIUA INT A DIZABILITATII, Daruri ptr artistii speciali-5.jpeg';
import p2 from './ZIUA INT A DIZABILITATII, Palatul National al Copiilor, 3.12.24-1..jpeg';
import p3 from './ZIUA INT A DIZABILITATII, Palatul National al Copiilor, 3.12.24-2.jpeg';
import p4 from './ZIUA INT A DIZABILITATII, Palatul National al Copiilor, 3.12.24-3.jpeg';
import p5 from './ZIUA INT A DIZABILITATII, Palatul National al Copiilor, 3.12.24-4.jpeg';

export const content = [
  {
            src: p1,
            width: 2000,
            height: 1500
          },
  {
            src: p2,
            width: 2000,
            height: 1500
          },
  {
            src: p3,
            width: 2000,
            height: 1500
          },
  {
            src: p4,
            width: 2048,
            height: 1443
          },
  {
            src: p5,
            width: 1500,
            height: 2000
          }
];