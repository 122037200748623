import p1 from './Palatul National al Copiilor, Bucuresti, RAOUL SI MIRABELA, 22.12.24 - 1.jpeg';
import p2 from './Palatul National al Copiilor, Bucuresti, RAOUL SI MIRABELA, 22.12.24 - 2.jpeg';
import p3 from './Palatul National al Copiilor, Bucuresti, RAOUL SI MIRABELA, 22.12.24 - 3.jpeg';
import p4 from './Palatul National al Copiilor, Bucuresti, RAOUL SI MIRABELA, 22.12.24 - 4.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 1200,
            height: 1600
          },
  {
            src: p3,
            width: 945,
            height: 2048
          },
  {
            src: p4,
            width: 945,
            height: 2048
          }
];