import p1 from './eveniment BUSINESS DisABILITY SUMMIT, Palatul Parlamentului,17.05.24-1.jpeg';
import p2 from './eveniment BUSINESS DisABILITY SUMMIT, Palatul Parlamentului,17.05.24-2.jpeg';
import p3 from './eveniment BUSINESS DisABILITY SUMMIT, Palatul Parlamentului,17.05.24-3.jpeg';
import p4 from './eveniment BUSINESS DisABILITY SUMMIT, Palatul Parlamentului,17.05.24-4.jpeg';
import p5 from './eveniment BUSINESS DisABILITY SUMMIT, Palatul Parlamentului,17.05.24-5.jpeg';
import p6 from './eveniment BUSINESS DisABILITY SUMMIT, Palatul Parlamentului,17.05.24-6.jpeg';
import p7 from './eveniment BUSINESS DisABILITY SUMMIT, Palatul Parlamentului,17.05.24-7.jpeg';
import p8 from './eveniment BUSINESS DisABILITY SUMMIT, Palatul Parlamentului,17.05.24-8.jpeg';
import p9 from './eveniment BUSINESS DisABILITY SUMMIT, Palatul Parlamentului,17.05.24-9.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 1500,
            height: 2000
          },
  {
            src: p3,
            width: 1500,
            height: 2000
          },
  {
            src: p4,
            width: 1500,
            height: 2000
          },
  {
            src: p5,
            width: 1500,
            height: 2000
          },
  {
            src: p6,
            width: 1500,
            height: 2000
          },
  {
            src: p7,
            width: 1500,
            height: 2000
          },
  {
            src: p8,
            width: 1500,
            height: 2000
          },
  {
            src: p9,
            width: 1500,
            height: 2000
          }
];