import p1 from './WhatsApp Image 2025-01-04 at 21.18.59.jpeg';
import p2 from './WhatsApp Image 2025-01-04 at 21.19.00 (1).jpeg';
import p3 from './WhatsApp Image 2025-01-04 at 21.19.00 (2).jpeg';
import p4 from './WhatsApp Image 2025-01-04 at 21.19.00 (3).jpeg';
import p5 from './WhatsApp Image 2025-01-04 at 21.19.00.jpeg';
import p6 from './WhatsApp Image 2025-01-04 at 21.19.01 (1).jpeg';
import p7 from './WhatsApp Image 2025-01-04 at 21.19.01 (2).jpeg';
import p8 from './WhatsApp Image 2025-01-04 at 21.19.01 (3).jpeg';
import p9 from './WhatsApp Image 2025-01-04 at 21.19.01 (4).jpeg';
import p10 from './WhatsApp Image 2025-01-04 at 21.19.01.jpeg';

export const content = [
  {
            src: p1,
            width: 1080,
            height: 1920
          },
  {
            src: p2,
            width: 1200,
            height: 1599
          },
  {
            src: p3,
            width: 1200,
            height: 1600
          },
  {
            src: p4,
            width: 901,
            height: 1600
          },
  {
            src: p5,
            width: 1200,
            height: 1600
          },
  {
            src: p6,
            width: 1600,
            height: 1200
          },
  {
            src: p7,
            width: 1600,
            height: 1200
          },
  {
            src: p8,
            width: 1600,
            height: 1200
          },
  {
            src: p9,
            width: 1200,
            height: 1600
          },
  {
            src: p10,
            width: 1600,
            height: 1200
          }
];