import p1 from './tabara de integrare si socializare, SATURN, 2-7.09.24-1.jpeg';
import p2 from './tabara de integrare si socializare, SATURN, 2-7.09.24-10.jpeg';
import p3 from './tabara de integrare si socializare, SATURN, 2-7.09.24-2.jpeg';
import p4 from './tabara de integrare si socializare, SATURN, 2-7.09.24-3.jpeg';
import p5 from './tabara de integrare si socializare, SATURN, 2-7.09.24-4.jpeg';
import p6 from './tabara de integrare si socializare, SATURN, 2-7.09.24-5.jpeg';
import p7 from './tabara de integrare si socializare, SATURN, 2-7.09.24-6.jpeg';
import p8 from './tabara de integrare si socializare, SATURN, 2-7.09.24-7.jpeg';
import p9 from './tabara de integrare si socializare, SATURN, 2-7.09.24-8.jpeg';
import p10 from './tabara de integrare si socializare, SATURN, 2-7.09.24-9.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 2048,
            height: 2026
          },
  {
            src: p3,
            width: 2000,
            height: 1500
          },
  {
            src: p4,
            width: 1500,
            height: 2000
          },
  {
            src: p5,
            width: 1500,
            height: 2000
          },
  {
            src: p6,
            width: 1500,
            height: 2000
          },
  {
            src: p7,
            width: 1500,
            height: 2000
          },
  {
            src: p8,
            width: 1500,
            height: 2000
          },
  {
            src: p9,
            width: 1500,
            height: 2000
          },
  {
            src: p10,
            width: 1500,
            height: 2000
          }
];