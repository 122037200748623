import p1 from './MAJORAT 2.jpeg';
import p2 from './MAJORAT1.jpeg';
import p3 from './WhatsApp Image 2025-01-04 at 22.00.52.jpeg';
import p4 from './WhatsApp Image 2025-01-04 at 22.15.27.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 1500,
            height: 2000
          },
  {
            src: p3,
            width: 1200,
            height: 1600
          },
  {
            src: p4,
            width: 1530,
            height: 2040
          }
];