import p1 from './WhatsApp Image 2025-01-04 at 19.42.25.jpeg';
import p2 from './WhatsApp Image 2025-01-04 at 19.44.08.jpeg';
import p3 from './WhatsApp Image 2025-01-04 at 19.45.06.jpeg';
import p4 from './WhatsApp Image 2025-01-04 at 19.46.29.jpeg';
import p5 from './WhatsApp Image 2025-01-04 at 20.03.52.jpeg';
import p6 from './WhatsApp Image 2025-01-04 at 20.04.13.jpeg';
import p7 from './WhatsApp Image 2025-01-04 at 20.15.59.jpeg';
import p8 from './WhatsApp Image 2025-01-04 at 20.18.54.jpeg';
import p9 from './WhatsApp Image 2025-01-04 at 20.51.04.jpeg';
import p10 from './WhatsApp Image 2025-01-04 at 20.53.22.jpeg';
import p11 from './WhatsApp Image 2025-01-04 at 21.00.09.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 945,
            height: 2048
          },
  {
            src: p3,
            width: 2000,
            height: 1500
          },
  {
            src: p4,
            width: 945,
            height: 2048
          },
  {
            src: p5,
            width: 1500,
            height: 2000
          },
  {
            src: p6,
            width: 1500,
            height: 2000
          },
  {
            src: p7,
            width: 1500,
            height: 2000
          },
  {
            src: p8,
            width: 1500,
            height: 2000
          },
  {
            src: p9,
            width: 1500,
            height: 2000
          },
  {
            src: p10,
            width: 945,
            height: 2048
          },
  {
            src: p11,
            width: 899,
            height: 1599
          }
];