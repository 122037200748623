import p1 from './eveniment ZIUA INTERNATIONALA A SINDROMULUI DOWN, Pal Parlamentului-1..jpeg';
import p2 from './eveniment ZIUA INTERNATIONALA A SINDROMULUI DOWN, PaL Parlamentului3,.jpeg';
import p3 from './eveniment ZIUA INTERNATIONALA A SINDROMULUI DOWN, Pal Parlamentului4,.jpeg';
import p4 from './eveniment ZIUA INTERNATIONALA A SINDROMULUI DOWN, Pal Parlamentului5,.jpeg';
import p5 from './eveniment ZIUA INTERNATIONALA A SINDROMULUI DOWN, PalParlamentului2,.jpeg';

export const content = [
  {
            src: p1,
            width: 2000,
            height: 1500
          },
  {
            src: p2,
            width: 1500,
            height: 2000
          },
  {
            src: p3,
            width: 2000,
            height: 1500
          },
  {
            src: p4,
            width: 2000,
            height: 1500
          },
  {
            src: p5,
            width: 2000,
            height: 1500
          }
];