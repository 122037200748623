import p1 from './WhatsApp Image 2025-01-04 at 17.36.03.jpeg';
import p2 from './WhatsApp Image 2025-01-04 at 17.36.59.jpeg';
import p3 from './WhatsApp Image 2025-01-04 at 19.50.15.jpeg';
import p4 from './WhatsApp Image 2025-01-04 at 19.50.29.jpeg';
import p5 from './WhatsApp Image 2025-01-04 at 21.51.28.jpeg';
import p6 from './WhatsApp Image 2025-01-04 at 21.51.30.jpeg';

export const content = [
  {
            src: p1,
            width: 945,
            height: 2048
          },
  {
            src: p2,
            width: 945,
            height: 2048
          },
  {
            src: p3,
            width: 1500,
            height: 2000
          },
  {
            src: p4,
            width: 1500,
            height: 2000
          },
  {
            src: p5,
            width: 945,
            height: 2048
          },
  {
            src: p6,
            width: 945,
            height: 2048
          }
];