import p1 from './WhatsApp Image 2025-01-04 at 17.02.15.jpeg';
import p2 from './WhatsApp Image 2025-01-04 at 17.39.23.jpeg';
import p3 from './WhatsApp Image 2025-01-04 at 17.39.43.jpeg';
import p4 from './WhatsApp Image 2025-01-04 at 20.53.45.jpeg';
import p5 from './WhatsApp Image 2025-01-04 at 21.11.35.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 1500,
            height: 2000
          },
  {
            src: p3,
            width: 1500,
            height: 2000
          },
  {
            src: p4,
            width: 1500,
            height: 2000
          },
  {
            src: p5,
            width: 946,
            height: 1437
          }
];