import p1 from './spectacol TRIBAL, Cor de copii RADIO, Sala RADIO, Bucuresti, 22.06.24-1.jpeg';
import p2 from './spectacol TRIBAL, Cor de copii RADIO, Sala RADIO, Bucuresti, 22.06.24-2.jpeg';
import p3 from './spectacol TRIBAL, Cor de copii RADIO, Sala RADIO, Bucuresti, 22.06.24-3.jpeg';
import p4 from './spectacol TRIBAL, Cor de copii RADIO, Sala RADIO, Bucuresti, 22.06.24-4.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 1500,
            height: 2000
          },
  {
            src: p3,
            width: 1500,
            height: 2000
          },
  {
            src: p4,
            width: 1080,
            height: 1080
          }
];