import p1 from './WhatsApp Image 2025-01-04 at 22.00.23 (1).jpeg';
import p2 from './WhatsApp Image 2025-01-04 at 22.00.23 (2).jpeg';
import p3 from './WhatsApp Image 2025-01-04 at 22.00.23.jpeg';
import p4 from './WhatsApp Image 2025-01-04 at 22.00.28.jpeg';
import p5 from './WhatsApp Image 2025-01-04 at 22.00.35 (1).jpeg';
import p6 from './WhatsApp Image 2025-01-04 at 22.00.35.jpeg';
import p7 from './WhatsApp Image 2025-01-04 at 22.00.36.jpeg';
import p8 from './WhatsApp Image 2025-01-04 at 22.00.37.jpeg';
import p9 from './WhatsApp Image 2025-01-04 at 22.00.38.jpeg';
import p10 from './WhatsApp Image 2025-01-04 at 22.00.39.jpeg';
import p11 from './WhatsApp Image 2025-01-04 at 22.00.52 (1).jpeg';
import p12 from './WhatsApp Image 2025-01-04 at 22.00.52 (2).jpeg';
import p13 from './WhatsApp Image 2025-01-04 at 22.00.53 (1).jpeg';
import p14 from './WhatsApp Image 2025-01-04 at 22.00.53 (2).jpeg';
import p15 from './WhatsApp Image 2025-01-04 at 22.00.53.jpeg';
import p16 from './WhatsApp Image 2025-01-04 at 22.00.54 (1).jpeg';
import p17 from './WhatsApp Image 2025-01-04 at 22.00.54.jpeg';
import p18 from './WhatsApp Image 2025-01-04 at 22.00.55.jpeg';
import p19 from './WhatsApp Image 2025-01-04 at 22.00.56 (1).jpeg';
import p20 from './WhatsApp Image 2025-01-04 at 22.00.56.jpeg';
import p21 from './WhatsApp Image 2025-01-04 at 22.00.57 (1).jpeg';
import p22 from './WhatsApp Image 2025-01-04 at 22.00.57.jpeg';
import p23 from './WhatsApp Image 2025-01-04 at 22.00.58 (1).jpeg';
import p24 from './WhatsApp Image 2025-01-04 at 22.00.58 (2).jpeg';
import p25 from './WhatsApp Image 2025-01-04 at 22.00.58.jpeg';
import p26 from './WhatsApp Image 2025-01-04 at 22.00.59 (1).jpeg';
import p27 from './WhatsApp Image 2025-01-04 at 22.00.59.jpeg';
import p28 from './WhatsApp Image 2025-01-04 at 22.15.52.jpeg';
import p29 from './WhatsApp Image 2025-01-04 at 22.16.25.jpeg';

export const content = [
  {
            src: p1,
            width: 1600,
            height: 1200
          },
  {
            src: p2,
            width: 1600,
            height: 1200
          },
  {
            src: p3,
            width: 1459,
            height: 1600
          },
  {
            src: p4,
            width: 1500,
            height: 2000
          },
  {
            src: p5,
            width: 1200,
            height: 1600
          },
  {
            src: p6,
            width: 1200,
            height: 1600
          },
  {
            src: p7,
            width: 1200,
            height: 1600
          },
  {
            src: p8,
            width: 1200,
            height: 1600
          },
  {
            src: p9,
            width: 1349,
            height: 918
          },
  {
            src: p10,
            width: 1600,
            height: 900
          },
  {
            src: p11,
            width: 1530,
            height: 2040
          },
  {
            src: p12,
            width: 2048,
            height: 1536
          },
  {
            src: p13,
            width: 1530,
            height: 2040
          },
  {
            src: p14,
            width: 1530,
            height: 2040
          },
  {
            src: p15,
            width: 1530,
            height: 2040
          },
  {
            src: p16,
            width: 1600,
            height: 1200
          },
  {
            src: p17,
            width: 1530,
            height: 2040
          },
  {
            src: p18,
            width: 2040,
            height: 1530
          },
  {
            src: p19,
            width: 2040,
            height: 1530
          },
  {
            src: p20,
            width: 2048,
            height: 2048
          },
  {
            src: p21,
            width: 1530,
            height: 2040
          },
  {
            src: p22,
            width: 1530,
            height: 2040
          },
  {
            src: p23,
            width: 1530,
            height: 2040
          },
  {
            src: p24,
            width: 1530,
            height: 2040
          },
  {
            src: p25,
            width: 2048,
            height: 1152
          },
  {
            src: p26,
            width: 1530,
            height: 2040
          },
  {
            src: p27,
            width: 1530,
            height: 2040
          },
  {
            src: p28,
            width: 1302,
            height: 2048
          },
  {
            src: p29,
            width: 1600,
            height: 1200
          }
];