import p1 from './eveniment MOSUL PE LA NOI,  scoala CSEI nr. 1, Bucuresti, 6.12.24-1.jpeg';
import p2 from './eveniment MOSUL PE LA NOI,  scoala CSEI nr. 1, Bucuresti, 6.12.24-2.jpeg';
import p3 from './eveniment MOSUL PE LA NOI,  scoala CSEI nr. 1, Bucuresti, 6.12.24-3.jpeg';
import p4 from './eveniment MOSUL PE LA NOI,  scoala CSEI nr. 1, Bucuresti, 6.12.24-4.jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 2000,
            height: 1500
          },
  {
            src: p3,
            width: 1600,
            height: 1200
          },
  {
            src: p4,
            width: 979,
            height: 1600
          }
];