import p1 from './WhatsApp Image 2025-01-04 at 19.52.36.jpeg';
import p2 from './WhatsApp Image 2025-01-04 at 22.00.51.jpeg';
import p3 from './WhatsApp Image 2025-01-04 at 22.17.38 (1).jpeg';

export const content = [
  {
            src: p1,
            width: 1500,
            height: 2000
          },
  {
            src: p2,
            width: 1600,
            height: 1200
          },
  {
            src: p3,
            width: 1600,
            height: 900
          }
];